@import "styles/variables";

.root {
    :global {
        .content-wrapper {
            background: $bg-light;

        }

        lottie-player {
            width: 100%;
            max-width: 500px;
            height: auto;
        }
    }
}